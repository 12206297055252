/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


@font-face {
    font-family: 'AvenirNextBold';
    src: url('assets/fonts/avenir-next/AvenirNextLTPro-Bold.ttf') format("truetype"), url('assets/fonts/avenir-next/AvenirNextLTPro-Bold.woff') format("woff");
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'AvenirNext';
    src: url('assets/fonts/avenir-next/AvenirNextLTPro-Regular.ttf') format("truetype"), url('assets/fonts/avenir-next/AvenirNextLTPro-Regular.woff') format("woff");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'AvenirNextItalic';
    src: url('assets/fonts/avenir-next/AvenirNextLTPro-It.ttf') format("truetype"), url('assets/fonts/avenir-next/AvenirNextLTPro-It.woff') format("woff");
    font-weight: normal;
    font-style: normal;
}

* {
    font-family: 'AvenirNext';
}

body#main-app-body {
  background: #fefefe;
}
body#main-app-body.scanner-active {
  background: transparent;
  --background: transparent;
  --ion-background-color: transparent;

  ion-content {
    --background: transparent;
  }
}

.scroll-content {
    overflow-y: auto !important;
}

.alert-wrapper {
    min-width: 300px !important;
    // max-width: 300px !important;
}

.action-sheet-group {
    overflow: auto !important;
}

.alert-title {
    text-transform: capitalize !important;
}

.text-center {
    text-align: center;
}
ion-segment-button {
    // background-color: var(--ion-color-primary);
    
    &.segment-button-checked {
        background-color: var(--ion-color-primary);
        color: white !important;
        font-weight: bold;
    }
}

::part(indicator-background) {
    background-color: var(--ion-color-primary);
}


ion-spinner {
    display: block;
    margin: 20px auto;
}

.item.sc-ion-label-md-h, 
.item .sc-ion-label-md-h,
.sc-ion-label-md-h,
.alert-radio-label {
    white-space: normal !important;
}

.button-inner {
    font-family: 'AvenirNext';
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 2px;
}

ion-header {
    border-top-style: solid;
    border-top-color: var(--ion-color-primary);
    border-top-width: var(--ion-safe-area-top);
    
    //override ionic padding because we added the border to the top of the header
    ion-toolbar:first-of-type {
        padding-top: 0;
    }
    
    .toolbar-title-md {
        text-align: center;
    }
    #profile {
        background: #fff;
        position: relative;
        height: 75px;
        padding: 10px;
        z-index: 1;
        border: 5px solid #eee;
        
        #header-take-break {
            margin: 0;
            padding: 5px;
            font-size: 0.8rem;
            position: absolute;
            right: 5px;
            top: 5px;
        }

        h2 {
            font-size: 1rem;
            margin: 0;
            line-height: 15px;
            padding: 5px;
            color: #000;
            width: 72%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        span {
            font-size: 0.6rem;
            display: inline-block;
            margin: 0 3px;
            border-radius: 5px;
            padding: 0 5px;
            line-height: 16px;
            
            &.connection {
                border: 1px solid #c52727;
                color: #c52727;
                background: #ffa7a7;

                .dot {
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    display: inline-block;
                    margin-right: 5px;
                    background: #c52727;
                }
            }
            &.connected {
                border: 1px solid #25cb00;
                color: #25cb00;
                background: #e4f6e0;
                
                .dot {
                    background: #25cb00;
                }
            }

            p {
                text-align: center;
                line-height: 16px;
                border-radius: 20px;
                padding: 0 10px;
                color: white;
            }
            
        }
    }
}

ion-menu {
    ion-content {
        background: #fefefe !important;
    }
    ion-list {
        padding-top: 20px;

        ion-item {
            ion-badge {
                margin-left: 10px;
            }
            ion-icon {
                color: #fff;
                background: var(--ion-color-primary) !important;
                display: grid;
                align-content: center;
                justify-content: center;
                border-radius: 5px;
                margin-right: 10px;
                font-size: 1.6rem;
                width: 20px;
                height: 20px;
                padding: 5px;
                float: left;
            }
            .jobCount {
                font-weight: bold;
                color: var(--ion-color-primary);
            }
        }
    }
    .logout {
        text-align: center;
        font-size: 1.2rem;
        color: #c00;
        margin: 30px 0;
        padding: 0;
    }
    .versionNumber {
        font-size: 0.9rem;
        color: #aaa;
        text-align: center;
        padding: 15px 0 0;
        border-top: 1px solid #ccc;
    }
}


ion-content {
    font-family: 'AvenirNext';
    --background: #eee;

    h1.page_title {
        text-align: center;
        padding: 10px 0 15px 0;
        margin: 0 0 5px 0;
        font-weight: bold;
        font-size: 1rem;
        border-top: 1px solid #cecece;
        background: #fff;
        position: relative;
        
        #backArrow {
            position: absolute;
            top: 0;
            left: 20px;
            bottom: 0;
            height: 30px;
            font-size: 3rem;
            margin: auto;
        }
    }
}


body.keyboard-is-open {
    .scroll-content,
    .fixed-content {
        margin-bottom: 0 !important;
    }
    .tabbar {
        display: none;
    }
}

body.keyboard-is-open scroll-content {
    margin-bottom: 0 !important;
}

ion-app.platform-ios12 ion-content {
    pointer-events: auto;
}

ion-alert.select-alert.single-select-alert .alert-radio-label {
    white-space: pre-wrap;
}



ion-list.category {
    max-width: 600px;
    width: 100% !important;
    margin: 0 auto !important;
    padding: 13px 0;
    list-style: none;

    ion-item{
        --min-height: 30px;
        margin: 5px;
        background-color: #fff;
        cursor: pointer;
        
        ion-label {
            margin: 3px 0 !important;
        }
        ion-icon {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    p{
        font-size: 0.9rem;
        color: #000;
        margin: 5px;
        border-bottom: 1px solid #aaa;
        padding: 10px;
        cursor: pointer;

        ion-icon {
            padding-right: 5px;
        }
    }

    ion-card {
        margin-top: 5px;
        margin-bottom: 5px;
    }
    ion-card-content {
        padding: 0;

        .file-container {
            display: flex;
            justify-content: space-between;

            p {
                flex: 1;
            }
            .buttons-container {
                display: flex;
                flex-direction: column;
            }
        }
    }
}

.category .category {
    margin: 3px 0;
    width: 95%;

    ion-card {
        margin-right: 0;
    }

    ion-card:nth-child(odd) {
        > ion-item { 
            --background: #f3f3f3;
        }
    }
}




.stop-card {
    margin: 10px;

    &.savedSurvey {
        box-shadow: 0 0 10px var(--ion-color-primary);
        margin-bottom: 20px;

        h1 {
            color: var(--ion-color-primary);
        }
    }
    
    ion-card-header {
        border-bottom: 2px solid #ddd;

        h1 {
            text-align: center;
        }
        h3 {
            font-size: 1rem;
            margin: 0;
            padding: 0;
            color: black;
            font-weight: bold;
        }
    }

    ion-card-content {

        .address {
            display: inline-block;
            font-size: 0.8rem;
            margin: 7px 0;

            ion-icon {
                color: var(--ion-color-primary);
            }
        }
    }

    ion-grid {
        padding: 0 0 5px 0;
        
        ion-row {
            border-top: 2px solid #eee;
            padding-top: 5px;

            ion-col {
                padding: 0;
                border-right: 1px solid #eee;
            }
            ion-col:last-child {
                border: none;
            }
        }
        img {
            display: block;
            margin: 0 auto;
            width: 30px;
        }
        .jobTime {
            font-weight: bold;
            text-align: center;
            color: var(--ion-color-primary);
            line-height: 30px;
        }

        .circle {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin: 0 auto;
            border: 3px solid #aaa;

            ion-icon {
                width: 30px;
                height: 30px;
                font-size: 1rem;
                margin-top: -3px;
                margin-left: -3px;
            }
            
            .status_completed { color: #0a0; }
            .status_queued { color: #cc0; }
        }
        .status {
            height: 20px;
            font-size: 1rem;
            text-align: center;
            padding: 0;
            margin: 10px 0 0 0;
        }
        p.title {
            text-align: center;
            color: #aaa;
            margin: 0;
        }
    }
}